<template>
  <div class="verify_dialog">
    <er-dialog
      width="32%"
      :closeOnPressEscape="false"
      :visible="dialogVisible"
      :title="dialogConfig.title"
      :close-on-click-modal="false"
      @close="handleCloseDialog"
      @open="initUserFields"
    >
      <el-form
        @submit.native.prevent
        label-position="top"
        size="small"
        :model="user"
      >
        <p v-if="!sendOTP && !dialogConfig.is_empty" class="p-tag">
          <b>{{ $t(dialogConfig.oldData) }}:</b
          >{{ " " + dialogConfig.maskValue }}
        </p>
        <p v-else-if="sendOTP" class="p-tag p-verify-tag">
          {{ $t("comn_otp_msg") + " " +  getNewMaskValue}}
        </p>
        <el-form-item
          v-if="!sendOTP && dialogConfig.name === 'Mobile'"
          :label="$t('enter-new-mobile')"
          :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.mobile)"
        >
          <el-input
            v-model="user.mobile"
            :placeholder="$t('Usrs_enter_mobile_num')"
            :maxlength="userFieldLengths.mobile"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-else-if="!sendOTP && dialogConfig.name === 'Email'"
          :label="$t('enter-new-email')"
          :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
        >
          <el-input
            v-model="user.email"
            :placeholder="$t('Usrs_enter_email_addr')"
            :maxlength="userFieldLengths.email"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-else
          :label="$t('comn_enter_OTP')"
          :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.otp)"
        >
          <el-input
            v-model="otp"
            :placeholder="$t('comn_enter_OTP')"
            :maxlength="userFieldLengths.otp"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.otp)"
          ></el-input>
        </el-form-item>
      </el-form>
      <template v-if="!sendOTP">
        <template
          v-if="
            ftgm__isUserHasFeature(
              'allowed_mobile_login'
            )
          "
        >
          <p v-if="dialogConfig.name === 'Mobile'" class="p-tag otp-msg">
            {{ $t("user_mobile_otp_msg") }}
          </p>
        </template>
        <p v-if="dialogConfig.name === 'Email'" class="p-tag otp-msg">
          {{ $t("user_email_otp_msg") }}
        </p>
      </template>
      <div class="time-container" v-else>
        <template>
          <p class="p-tag time-tag" v-if="timer !== 0">
            {{ $t("Comn_time") }}: {{ formattedTimer }}
          </p>
          <p v-else></p>
        </template>
        <el-button
          type="text"
          class="resend-otp"
          :title="$t('resend_OTP')"
          :disabled="timer !== 0 || resendBtnLoading"
          @click="handleResendOTP(dialogConfig.name)"
          >{{ $t("resend_OTP") }}
        </el-button>
      </div>
      <div class="footer-container" slot="footer">
        <template v-if="!sendOTP">
          <er-button
            v-if="
              dialogConfig.name === 'Mobile' &&
              !ftgm__isUserHasFeature(
                'allowed_mobile_login'
              )
            "
            size="mini"
            btnType="save"
            :showLabel="false"
            :showIcon="true"
            :title="$t('Comn_update')"
            @click="submitUpdatedMobile"
            :loading="parentBtnLoading"
          >
            &nbsp; {{ $t("Comn_update") }}
          </er-button>
          <er-button
            v-else
            btnType="add"
            size="mini"
            :showLabel="false"
            :showIcon="false"
            :loading="btnLoading"
            :title="$t('send_otp')"
            @click="handleSendOtp(dialogConfig.name)"
          >
            {{ $t("send_otp") }}
          </er-button>
        </template>
        <template v-else>
          <er-button
            btnType="cancel"
            :title="$t('Back')"
            :showLabel="false"
            :showIcon="false"
            size="mini"
            @click="handleGoBack"
          >
            {{ $t("Back") }}
          </er-button>
          <er-button
            btnType="add"
            :showLabel="false"
            :showIcon="false"
            :title="$t('comn_verify')"
            size="mini"
            :loading="btnLoading"
            @click="handleVerifyOtp(dialogConfig.name)"
          >
            {{ $t("comn_verify") }}
          </er-button>
        </template>
      </div>
    </er-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import fieldLengths from "../../constants/fieldLengths";
import featureToggleMixin from "@/mixins/featureToggleMixin";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogConfig: {
      type: Object,
      default: () => ({}),
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    includeUserid: {
      type: Boolean,
      default: false,
    },
    ehm__errMessagesObjectCopy: {
      type: Object,
      default: () => ({}),
    },
    parentBtnLoading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    errorHandlerMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin,
    filtersMixin,
    featureToggleMixin,
  ],
  data: function () {
    return {
      userFieldLengths: fieldLengths,
      timer: 119,
      sendOTP: false,
      btnLoading: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      otp: "",
      resendBtnLoading: false
    };
  },
  watch: {
    ehm__errMessagesObjectCopy(newVal, oldVal) {
      this.ehm__errMessagesObject = this.ehm__errMessagesObjectCopy;
    },
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      // getProfileUrl: "getProfileUrl",
      getStoreUserProfileData: "getUserProfile",
    }),
    formattedTimer() {
      const minutes = Math.floor(this.timer / 60)
        .toString()
        .padStart(2, "0");
      const seconds = (this.timer % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    isValueGivenSameAsOldValue() {
      if (this.dialogConfig.name === "Mobile") {
        return this.user.mobile === this.dialogConfig.oldMobile;
      } else {
        return this.user.email === this.dialogConfig.oldEmail;
      }
    },
    getNewMaskValue() {
      if (this.dialogConfig.name === "Mobile") {
        return this.user.mobile
      }
      return this.user.email
    },
    isMobileLoginAllowed() {
      return this.ftgm__isUserHasFeature("allowed_mobile_login");
    },
    aekmm__keyToInvalidTranslations: function() {
      return {
        email: {
          INVALID_VALUE: "email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "max_254_char_for_field",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_char_for_field"
        },
        login_id: {
          INVALID_VALUE: "Usrs_email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "Usrs_email_in_valid_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_email_in_valid_format"
        },
        mobile: { INVALID_VALUE: (this.isMobileLoginAllowed ? "Comn_mobile_num_valid" : "Comn_phone_num_valid") },
        otp: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "comn_otp_error_msg",
          INVALID_VALUE: "invalid_OTP",
          VALUE_HAVE_MAX_CHAR: "comn_otp_error_msg"
        }
      };
    }
  },
  methods: {
    ...mapActions("user", {
      sendOTPFun: "sendOTP",
      verifyOTP: "verifyOTP",
    }),
    initUserFields() {
      this.sendOTP = false;
      this.otp = "";
      this.ehm__errMessagesObject = new User();
      this.user = this.$lodash.cloneDeep(this.userData);
      if (this.dialogConfig.is_verified) {
        if (this.dialogConfig.name === "Mobile") {
          this.user.mobile = "";
        } else {
          this.user.email = "";
        }
      }
    },
    async handleResendOTP(data) {
      if (this.timer === 0) {
        this.resendBtnLoading = true
        try {
          this.ehm__errMessagesObject = { otp: "", ...new User() };
          const payload = this.includeUserid
            ? { user_type: this.user.user_type, user_id: this.user._id }
            : {};
          if (data === "Mobile") {
            await this.sendOTPFun({ mobile: this.user.mobile, ...payload });
          } else {
            await this.sendOTPFun({ email: this.user.email, ...payload });
          }
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("otp_sent_success_msg"),
            duration: 5000,
            type: "success",
          });
          this.timer = 119;
          this.startTimer();
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.resendBtnLoading = false
        }
      }
    },
    startTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async handleSendOtp(data) {
      try {
        this.btnLoading = true;
        this.ehm__errMessagesObject = { otp: "", ...new User() };
        const payload = this.includeUserid
          ? { user_type: this.user.user_type, user_id: this.user._id }
          : {};
        if (data === "Mobile") {
          await this.sendOTPFun({ mobile: this.user.mobile || "", ...payload });
        } else {
          await this.sendOTPFun({ email: this.user.email || "", ...payload });
        }
        this.startTimer();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("otp_sent_success_msg"),
          duration: 5000,
          type: "success",
        });
        this.timer = 119;
        this.startTimer();
        this.sendOTP = !this.sendOTP;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.btnLoading = false;
      }
    },
    handleGoBack() {
      this.otp = "";
      this.sendOTP = !this.sendOTP;
    },
    async handleVerifyOtp(data) {
      try {
        this.ehm__errMessagesObject = { otp: "", ...new User() };
        this.btnLoading = true;
        const payload = this.includeUserid
          ? { user_type: this.user.user_type, user_id: this.user._id }
          : {};
        if (data === "Mobile") {
          await this.verifyOTP({
            mobile: this.user.mobile,
            otp: this.otp,
            ...payload,
          });
        } else {
          await this.verifyOTP({
            email: this.user.email,
            otp: this.otp,
            ...payload,
          });
        }
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(
            data === "Mobile"
              ? "mobile-verified-successfully"
              : "email-verified-successfully"
          ),
          duration: 5000,
          type: "success",
        });
        this.$emit("handleVerifyOtp", data);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.btnLoading = false;
      }
    },
    async handleCloseDialog() {
      const resetAndEmit = () => {
        // this.user.mobile = this.dialogConfig.oldMobile;
        // this.user.email = this.dialogConfig.oldEmail;
        this.$emit("handleCloseDialog", closeConfig);
      }
      const closeConfig = {
        field: this.dialogConfig.name,
        formState: this.sendOTP ? "OTP" : "NEW_FIELD",
        isFieldEditted:
          this.dialogConfig.name === "Mobile"
            ? this.user.mobile !== this.dialogConfig.oldMobile
            : this.user.email !== this.dialogConfig.oldEmail,
        isConfirmRequired: this.dialogVisible
      }
      if (closeConfig.isConfirmRequired) {
        if (closeConfig.formState === "NEW_FIELD") {
          resetAndEmit()
          return;
        }
        const message = closeConfig.formState === "NEW_FIELD" ? this.$t('closing-new-field-changes-discard') : this.$t('closing-otp-verification-confirm')
        try {
          await this.$confirm( message,
            "",
            {
              confirmButtonText: this.$t("ok"),
              cancelButtonText: this.$t("Comn_cancel"),
              cancelButtonClass: "er-button er-button--default",
              confirmButtonClass: "er-button er-button--confirm",
              type: "warning"
            }
          );
          resetAndEmit();
        } catch (err) {
          console.log(err)
        }
      } else {
        resetAndEmit()
      }
    },
    submitUpdatedMobile() {
      this.$emit("submitUpdatedMobile", this.user);
    },
    ehm__error403Handler(err, isAuthenticated) {
      const errorData = err.response.data;
      if (errorData.error_code === "INVALID_OTP") {
        this.ehm__errMessagesObject.otp = {
          key: "otp",
          error_code: "INVALID_VALUE",
        };
        return;
      }
      if (errorData.error_code === "OTP_EXPIRED") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("OTP_expired"),
          type: "error",
        });
      }
    },
    ehm__error409Handler(err, isAuthenticated) {
      const errorData = err.response.data;
      if (errorData.error_code === "EMAIL_ALREADY_EXISTS") {
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Email_already_registered"),
          type: "error",
        });
      }
      if (
        this.isValueGivenSameAsOldValue &&
        (["ACCOUNT_ALREADY_VERIFIED"].includes(errorData.error_code))
      ) {
        this.$notify({
          title: this.$t("failed"),
          message:
            this.dialogConfig.name === "Mobile"
              ? this.$t("mobile_already_Verified")
              : this.$t("Email_already_Verified"),
          type: "error",
        });
      } else if (errorData.error_code === "ACCOUNT_ALREADY_EXISTS") {
        this.$notify({
          title: this.$t("failed"),
          message:
            this.dialogConfig.name === "Mobile"
              ? this.$t("Comn_mobile_num_exist")
              : this.$t("Comn_email_exist"),
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.verify_dialog {
  .el-dialog {
    border-radius: 10px;

    .p-tag {
      opacity: 0.7;
      font-size: 12px;
      margin: 10px 0px 5px;
      color: #000000;
    }

    .el-dialog__body {
      padding: 5px 20px 0px;
    }

    .el-dialog__header {
      padding: 16px 20px 10px;
    }

    .el-dialog__footer {
      padding: 16px 20px 10px;
      display: flex;
      justify-content: center;
    }

    .el-dialog__headerbtn {
      top: 18px;

      .el-dialog__close {
        color: #f54e5e;
        @include responsiveProperty(
          font-size,
          $app_font_size_2,
          $app_font_size_3,
          $app_font_size_xl
        );
      }
    }

    .el-form--label-top .el-form-item__label {
      padding: 0px;
      font-weight: 700;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }

    .el-form-item--small {
      display: block;
      margin-bottom: 0px;
    }

    .el-form-item__content {
      width: 100%;
    }

    .otp-msg {
      padding: 16px 16px 16px;
      text-align: center;
      word-break: break-word;
    }

    .time-tag {
      padding-top: 6px;
      text-align: left;
    }

    .resend-otp {
      padding-top: 16px;
      text-align: right;
      font-size: 12px;
    }

    .time-container {
      padding-top: 6px;
      display: flex;
      justify-content: space-between;
    }

    .p-verify-tag {
      text-align: center;
      word-break: break-word;
    }
  }
}
</style>
